import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { combineDateAndTime, millisToMinutesAndSeconds } from 'utils/functions';
import { CalendarFilled } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { loadAgenda, notifAgenda } from 'redux/actions/manage-project/agenda';

export const NotificationContext = React.createContext();

const NotificationProvider = ({
  children,
  current,
  byWorkspaces,
  agendaData,
  currentWorkspaceData,
  loadAgenda,
  isNotified,
  notifAgenda,
}) => {
  const [allProject, setAllProject] = useState([]);

  useEffect(() => {
    if (current) {
      loadAgenda(current._id, current.email);
    }
    if (byWorkspaces && current && currentWorkspaceData) {
      setAllProject(() => {
        const projects = byWorkspaces.filter(
          (elm) =>
            currentWorkspaceData.owner === current._id ||
            elm.owner === current._id ||
            elm.members.some((member) => member.email === current.email)
        );
        return projects;
      });
    }
  }, [byWorkspaces, current, currentWorkspaceData]);

  // REQUEST NOTIFICATIONS PERMISSIONS
  if (!('Notification' in window)) {
    console.log('Browser does not support desktop notification');
  } else {
    Notification.requestPermission();
  }

  useEffect(() => {
    if (allProject && !isNotified) {
      agendaData.forEach((a) => {
        const currentDate = new Date();
        const aDate = combineDateAndTime(a.date, a.start);
        const diff = aDate - currentDate;

        const currentProject = allProject.find((p) => p._id === a.projectId);
        if (
          !a?.members ||
          a.members.length === 0 ||
          (a.members.length > 0 && a.members.includes(current._id))
        ) {
          if (currentProject) {
            const body = `Vous aurez un evenement en moins dans ${millisToMinutesAndSeconds(
              diff
            )}.`;
            const title = currentProject.name + ' - ' + a.title;

            let options = {
              body: body,
              icon: 'https://icons.iconarchive.com/icons/dtafalonso/android-lollipop/512/calendar-icon.png',
              dir: 'ltr',
            };
            if (diff > 0 && diff < 600000) {
              if (diff <= 300000) {
                const key = `open${Date.now()}`;
                notification.open({
                  message: title,
                  description: body,
                  key,
                  icon: <CalendarFilled style={{ color: '#dc3545' }} />,
                  placement: 'bottomRight',
                  duration: 20,
                });
                const notif = document.getElementById('notif-event-mp3');
                notif.play();
                new Notification(title, options);
              } else {
                const key = `open${Date.now()}`;
                notification.open({
                  message: title,
                  description: body,
                  key,
                  icon: <CalendarFilled style={{ color: '#0dcaf0' }} />,
                  placement: 'bottomRight',
                  duration: 20,
                });
                new Notification(title, options);
              }
              notifAgenda(true);
            }
          }
        }
      });
    }
  }, [isNotified, allProject]);

  return (
    <NotificationContext.Provider value={{ allProject }}>
      {children}
    </NotificationContext.Provider>
  );
};

const mapStateToProps = ({ workspaces, auth, project, agenda }) => {
  const { currentWorkspaceData } = workspaces;
  const { current } = auth;
  const { byWorkspaces } = project;
  const { agendaData, isNotified } = agenda;
  return {
    current,
    byWorkspaces,
    agendaData,
    isNotified,
    currentWorkspaceData,
  };
};

export default withRouter(
  connect(mapStateToProps, { loadAgenda, notifAgenda })(NotificationProvider)
);
