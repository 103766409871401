import {
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
} from 'redux/constants/user/users';

export const getUsersAction = () => {
  return { type: GET_USERS };
};

export const getUsersActionSuccess = (data) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: data,
  };
};

export const showUsersMessage = (message) => {
  return {
    type: GET_USERS_ERROR,
    message,
  };
};
