import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getUsersAction } from 'redux/actions/user/users';

export const UserContext = React.createContext();

const UserProvider = ({ children, current }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (current) {
      dispatch(getUsersAction());
    }
  }, [current]);

  return <UserContext.Provider value={{}}>{children}</UserContext.Provider>;
};

const mapStateToProps = ({ auth }) => {
  const { current } = auth;
  return { current };
};

export default connect(mapStateToProps)(UserProvider);
