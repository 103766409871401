import { GET_USERS_ERROR, GET_USERS_SUCCESS } from 'redux/constants/user/users';

const initialState = {
  users: [],
  message: '',
  error: false,
};

function UserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return { ...state, users: action.payload, message: '', error: false };

    case GET_USERS_ERROR:
      return { ...state, users: [], message: action.message, error: true };

    default:
      return state;
  }
}

export default UserReducer;
