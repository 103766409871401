import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  getUsersActionSuccess,
  showUsersMessage,
} from 'redux/actions/user/users';
import { GET_USERS } from 'redux/constants/user/users';
import { getUsersAPI } from 'services/UserService';

export function* getUsers() {
  yield takeEvery(GET_USERS, function* () {
    try {
      const response = yield call(getUsersAPI);
      if (response.data?.data) {
        yield put(getUsersActionSuccess(response.data.data));
      }
    } catch (error) {
      yield put(showUsersMessage(error.response ? error.response.data : error));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getUsers)]);
}
